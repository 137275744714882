
import { defineComponent, onMounted, ref } from 'vue';
import FixedScheduleDisplay from '@/components/gem-farm/FixedScheduleDisplay.vue';
import { parseDate, getEstimatedRewards } from '@/common/util';
import numeral from 'numeral';
import useCluster from '@/composables/cluster';

export default defineComponent({
  components: { FixedScheduleDisplay },
  props: {
    reward: Object,
    farmReward: Object,
    gems: Number,
    title: String,
  },
  data() {
    return {
      LAMPORTS_PER_SOL: 1000000000
    }
  },

  created() {

  },
  
  setup(props) {
    const { cluster, getConnection } = useCluster();

    const parseRewardType = (reward: any): string => {
      //returns "variable" or "fixed"
      return Object.keys(reward.rewardType)[0];
    };
    let currentTS = 0;
    let estimatedRewards = ref<string>();

    onMounted(async () => {
      if (getConnection()) {
          const conn = await getConnection()
          const lastSlot = (await conn.getEpochInfo())?.absoluteSlot
          const lastBlockTS = (await conn.getBlockTime(lastSlot))*1000
          currentTS = lastBlockTS;
          setInterval(() => {
            currentTS = currentTS + 1000;
            estimatedRewards.value = getEstimatedRewards(props.reward, props.gems, currentTS).toFixed(4)
          }, 1000)
      }
    });

    return {
      parseRewardType,
      parseDate,
      numeral,
      getEstimatedRewards,
      estimatedRewards
    };
  },
});
