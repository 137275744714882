
import { DEFAULTS } from '@/globals';
import { defineComponent, ref } from 'vue';
export default defineComponent({
  props: {
    schedule: Object,
    gems: Number,
  },
  setup() {
    const dustRate = ref<Number>()

    dustRate.value = DEFAULTS.DUST_RATE;
    return {
      dustRate
    };
  },
});
