
import { defineComponent, ref } from 'vue';
export default defineComponent({
  props: {
    nft: { type: Object, required: true },
    staked: Boolean,
    selectedNFT: Object,
  },
  emits: ['selected'],
  setup(props, ctx) {
    const mint_rank = require('@/common/util/mint_rank.json')
    const selected = ref<boolean>(false);
    const toggleSelect = () => {
      if (!(selected.value === true && props.selectedNFT !== props.nft)) {
        selected.value = !selected.value;
      }
      console.log('selected', props.nft.mint.toBase58());
      ctx.emit('selected', {
        nft: props.nft,
        selected: selected.value,
      });
    };

    const selectNFT = () => {
      ctx.emit('selected', {
        nft: props.nft,
        selected: selected.value,
      });
    }

    return {
      mint_rank,
      selected,
      selectNFT,
      toggleSelect,
    };
  },
});
